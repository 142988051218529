<app-header-horeca></app-header-horeca>
<div class="d-flex justify-content-center align-items-center flex-column">
  <div class="w-100 contenedor p-5" *ngIf="pedido">
    <!--Header-->
    <section class="wrapper-titulo mb-3"> 
      <div class="contenedor-titulos w-100 m-0">
        <div class="d-flex justify-content-start align-items-center rounded titulo-pedido">
          <div class="boton-volver rounded mr-3 cursorPointer" [routerLink]="['/pedidos']">
            <fa-icon [icon]="faChevronLeft" [styles]="{ color: '#41403E' }" id="fa-icon" class="mr-2"></fa-icon>
            <span>Volver</span>
          </div>
          <h3
            *ngIf="
              pedido.estado &&
              !pedido.estado.startsWith('Cancelado') &&
              !(pedido.estado == 'Recibido' || pedido.estado == 'Entregado' || pedido.estado == 'Calificado')
            "
            class="m-1 m-sm-0">
            Pedido en curso ID: {{ pedido?.id_pedido }} 
          </h3>
          <h3
            class="m-1 m-sm-0"
            *ngIf="pedido.estado == 'Recibido' || pedido.estado == 'Entregado' || pedido.estado == 'Calificado'">
            Pedido finalizado ID: {{ pedido?.id_pedido }}
          </h3>
          <h3 class="m-1 m-sm-0" *ngIf="pedido.estado && pedido.estado.startsWith('Cancelado')">
            Pedido cancelado ID: {{ pedido?.id_pedido }}
          </h3>
        </div>
        <div class="tarjeta rounded d-flex flex-row w-100">
          <div
            class="col-2 d-flex justify-content-center align-items-center overflow-hidden p-0 rounded-left"
            id="icon-ubicacion">
            <img
              src="../../assets/img/icons/icon-pin-map.png"
              alt="Icono de un pin para señalar un punto en un mapa"
              srcset="" />
          </div>
          <div class="col-8 pr-0 d-flex justify-content-center align-items-center">
            <p class="p-card-ubicacion text-left w-100 sub-title m-0">
              {{ pedido.punto_entrega.nombre }}
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="wrapper-contenido d-flex justify-content-center w-100">
      <div class="row col-12 mt-4" *ngIf="pedido?.tiempo_tracking_hora == null">
        <div class="col-12 text-center">
          <img src="../../../assets/img/icon-warning-amarillo.png" alt="Advertencia" id="icon-warning" />
          <h5>Nada de nada</h5>
          <p>Este punto de entrega no tiene pedidos sugeridos, intenta con otro.</p>
        </div>
      </div>
      <!--Resumen-->
      <div class="contenedor-principal row mt-4 w-100" *ngIf="pedido?.tiempo_tracking_hora != null">
        <div class="contenedor-resumen">
          <div class="card">
            <div class="card-body p-4 card-body-primer">
              <!--Estado-->
              <div class="row">
                <div class="col-12">
                  <p>
                    <fa-icon
                      *ngIf="pedido.estado == 'Recibido'"
                      [icon]="faCircle"
                      size="sm"
                      [styles]="{ color: '#97AE22' }"></fa-icon>
                    <fa-icon
                      *ngIf="pedido.estado == 'Pendiente'"
                      [icon]="faCircle"
                      size="sm"
                      [styles]="{ color: '#D2D2D2' }"></fa-icon>
                    <fa-icon
                      *ngIf="pedido.estado == 'Aprobado Interno'"
                      [icon]="faCircle"
                      size="sm"
                      [styles]="{ color: '#FDEAEA' }"></fa-icon>
                    <fa-icon
                      *ngIf="pedido.estado == 'Aprobado Externo'"
                      [icon]="faCircle"
                      size="sm"
                      [styles]="{ color: '#FAF1CC' }"></fa-icon>
                    <fa-icon
                      *ngIf="pedido.estado == 'Alistamiento'"
                      [icon]="faCircle"
                      size="sm"
                      [styles]="{ color: '#EEF2DA' }"></fa-icon>
                    <fa-icon
                      *ngIf="pedido.estado == 'Despachado'"
                      [icon]="faCircle"
                      size="sm"
                      [styles]="{ color: '#E6F0B4' }"></fa-icon>
                    <fa-icon
                      *ngIf="pedido.estado == 'Facturado'"
                      [icon]="faCircle"
                      size="sm"
                      [styles]="{ color: '#E6F0B4' }"></fa-icon>
                    <fa-icon
                      *ngIf="pedido.estado == 'Entregado'"
                      [icon]="faCircle"
                      size="sm"
                      [styles]="{ color: '#E6F0B4' }"></fa-icon>
                    <fa-icon
                      *ngIf="pedido.estado == 'Calificado'"
                      [icon]="faCircle"
                      size="sm"
                      [styles]="{ color: '#ACACAC' }"></fa-icon>
                    <fa-icon
                      *ngIf="pedido.estado.startsWith('Cancelado')"
                      [icon]="faCircle"
                      size="sm"
                      [styles]="{ color: '#D38989' }"></fa-icon>
                    {{ pedido.estado }}
                    {{ fecha_estado }} a las {{ hora_estado }}
                  </p>
                </div>
              </div>
              <div>
                <hr />
              </div>
              <!--Barra de estado-->
              <div class="row mb-2">
                <div class="col-12" *ngIf="!pedido.estado.startsWith('Cancelado')">
                  <ngb-progressbar type="warning" [max]="8" height="1 rem" [value]="num_estado">
                    Paso {{ num_estado }} de 8
                  </ngb-progressbar>
                  <p
                    *ngIf="
                      pedido.estado != 'Calificado' &&
                      pedido.estado != 'Recibido' &&
                      !pedido.estado.startsWith('Cancelado')
                    ">
                    Pedido en curso
                  </p>
                </div>
                <div class="col-12" *ngIf="pedido.estado.startsWith('Cancelado')">
                  <ngb-progressbar type="danger" [max]="1" height="1 rem" [value]="1">Pedido cancelado</ngb-progressbar>
                  <p
                    *ngIf="
                      pedido.estado == 'Calificado' ||
                      pedido.estado == 'Recibido' ||
                      pedido.estado.startsWith('Cancelado')
                    ">
                    Pedido finalizado
                  </p>
                </div>
              </div>
              <div>
                <hr />
              </div>
              <!--Precio-->
              <div class="row">
                <div class="col-12">
                  <p>Resumen</p>
                </div>
              </div>
              <div class="row">
                <div class="col-6 text-left">
                  <p>Subtotal</p>
                </div>
                <div class="col-6 text-right">
                  <p>
                    {{ pedido?.subtotal_pedido || pedido?.total_pedido || 0 | currency : '$ ' : 'symbol' : '1.0-0' }}
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-6 text-left">
                  <p>Descuento</p>
                </div>
                <div class="col-6 text-right">
                  <p>
                    {{ pedido?.descuento_pedido > 0 ? '-' : ''
                    }}{{ pedido?.descuento_pedido || 0 | currency : '$ ' : 'symbol' : '1.0-0' }}
                  </p>
                </div>
              </div>
              <div class="row mb-2 mb-sm-5">
                <div class="col-4 text-left">
                  <h4>Total</h4>
                </div>
                <div class="col-8 text-right">
                  <h4>{{ pedido?.total_pedido || 0 | currency : '$ ' : 'symbol' : '1.0-0' }}</h4>
                </div>
              </div>
              <div>
                <hr />
              </div>
              <!--Detalles-->
              <div class="row">
                <div class="col-12">
                  <p>Detalles</p>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-12 text-left">
                  <p>Códigos redimidos:</p>
                </div>
                <div class="col-12 col-sm-12 d-flex justify-content-end align-items-center">
                  <div
                    class="pill-categoria2"
                    *ngIf="pedido.codigo_descuento.length > 0">
                    <p *ngFor="let cod of pedido.codigo_descuento">
                      {{cod.codigo_creado}} ({{cod.valor_moneda | currency: 'COP':'symbol':'1.0-0' }})
                    </p>
                  </div>
                  <div
                    class="pill-categoria2"
                    *ngIf="!pedido.codigo_descuento || pedido.codigo_descuento.length === 0">
                    <p>No se usaron código</p>
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="pedido.metodo_pago">
                <div class="col-5 text-left">
                  <p>Método de pago:</p>
                </div>
                <div class="col-7 text-right">
                  <p class="metodoPago">{{ pedido.metodo_pago }}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-8 text-left">
                  <p>Puntos FT ganados</p>
                </div>
                <div class="col-4 text-right">
                  <p>
                    {{ pedido?.puntos_ganados }}
                  </p>
                </div>
              </div>
              <div>
                <hr />
              </div>
              <div *ngIf="distribuidor">
                <button
                *ngIf="distribuidor.urlPago"
                style="margin-bottom: 18px;"
                (click)="goUrlPago(distribuidor.urlPago)">
                Link de pago
              </button>
              </div>
              <div>
                <hr />
              </div>
              <!--Cargar pedido al carrito-->
              <div class="row">
                <div class="col-12" *ngIf="pedido.estado !== 'Recibido'">
                  <button
                  *ngIf="
                  pedido.estado == 'Alistamiento' ||
                  pedido.estado == 'Pendiente' ||
                  pedido.estado == 'Aprobado' ||
                  pedido.estado == 'Aprobado Interno' ||
                  pedido.estado == 'Aprobado Externo'
              "
                    class="btn-cargar-carrito w-50 text-left cursorPointer"
                    (click)="popUpCancelarPedido()">
                    X Cancelar pedido
                  </button>
                  <button
                    *ngIf="validacionVerPrefactura"
                    class="btn-cargar-carrito w-50 text-left"
                    [ngClass]="{'text-right': validacionCancelarPedido}"
                    routerLink="/pedidos/pre-factura/{{ pedido._id }}">
                    Ver pre-factura
                  </button>
                  <button
                    *ngIf="pedido.estado === 'Entregado'"
                    class="btn-cargar-carrito w-50 mb-2"
                    [ngClass]="{'text-left': validacionCancelarPedido, 'text-right': !validacionCancelarPedido}"
                    (click)="marcarRecibido()">
                    
                    Marcar pedido "Recibido"
                  </button>
                </div>
                <div class="col-12">
                  <!--<button class="btn-cargar-carrito w-50 text-left" (click)="volverPedir()"
                  *ngIf="estadosVolverPedir.includes(pedido.estado)"
                  >
                    <fa-icon
                    class="medida-volver-pedir"
                    [icon]="faArrowAltCircleRight"
                    size="sm"
                    [styles]="{ color: '#8e6ff7' }"></fa-icon>
                    Volver a pedir.</button>-->
                  <button
                    class="btn-cargar-carrito w-50 text-right"
                    (click)="onCalificar()"
                    *ngIf="pedido.estado === 'Recibido' || pedido.estado === 'Calificado' && pedido.estado !== 'Calificado'">
                    Calificar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--Detalle distribuidor-->
        <div class="contenedor-distribuidor max-data-distribuidor">
          <div class="card p-4" *ngIf="!flag_tiempo_total_entrega; else tiempo_entrega">
            <!--Distribuidor-->
            <div class="row contenedor-distribuidor-items">
              <div class="col-12 col-sm-7 row m-0 pr-0">
                <div class="col-12 col-sm-4 col-xl-3 p-0">
                  <img
                    src="{{ distribuidor?.logo || distribuidor_placeholder }}"
                    class="icon-dist rounded"
                    alt="logo {{ distribuidor?.nombre }}" />
                </div>
                <div class="col-12 col-sm-8 col-xl-9 pr-0">
                  <p>
                    {{ distribuidor?.nombre }}
                  </p>
                  <p class="pill-categoria">
                    {{ distribuidor?.tipo }}
                  </p>
                </div>
              </div>
              <div class="col-12 col-sm-5 d-flex justify-content-end">
                <button
                  class="btn-cargar-carrito d-flex align-content-end justify-content-start text-right"
                  routerLink="/pedidos/mensajes/{{ pedido._id }}">
                  Mensaje al distribuidor
                </button>
              </div>
            </div>
            <!--Vendedor-->
            <div class="w-100 text-left p-0 pt-3">
              <p class="m-0">
                <img src="../../../assets/img/icons/icon-bus.png" alt="icono-bus" class="icono-detalle-dist" />
                &nbsp;Tiempos entrega: {{ distribuidor?.tiempo_entrega }}
              </p>
            </div>
            <!--Dirección-->
            <div class="w-100 text-left p-0 my-1">
              <p class="m-0">
                <img src="../../../assets/img/icons/icon-pin-map.png" alt="icono-pin-mapa" class="icono-detalle-dist" />
                &nbsp;{{ pedido?.direccion }}
              </p>
            </div>
            <!--Teléfono-->
            <div class="w-100 text-left p-0">
              <p class="m-0">
                <img
                  src="../../../assets/img/icons/icon-telephone.png"
                  alt="icono-telefono"
                  class="icono-detalle-dist-2" />
                &nbsp;{{ distribuidor?.celular }} - {{ distribuidor?.telefono }}
              </p>
            </div>
            <div class="w-100 p-0">
              <button class="btn-purple-trans pl-0 pb-0 w-100" (click)="openModalInfoEncargado()">
                Información del encargado
              </button>
            </div>
            <div class="w-100 text-left p-0">
              <p class="m-0">
                <img
                  src="../../../assets/img/icons/icono-26.png"
                  alt="icono-telefono"
                  class="icono-detalle-dist-2" />
                &nbsp;Descripcion e información:
              </p>
              <p class="m-0">
               {{ distribuidor?.descripcion }}
              </p>
            </div>
          </div>
          <ng-template #tiempo_entrega>
            <div class="card p-4 d-flex flex-row">
              <div class="col-4 d-flex flex-column pl-0">
                <p>Aprobado:</p>
                <p>{{ tiempos_pedido_finalizado.aprobado || '' }}</p>
              </div>
              <div class="col-4 d-flex flex-column pl-0">
                <p>Entregado:</p>
                <p>{{ tiempos_pedido_finalizado.entregado || '' }}</p>
              </div>
              <div class="col-4 d-flex flex-column p-0">
                <p>Tiempo de entrega:</p>
                <p *ngIf="tiempos_pedido_finalizado.total_horas">
                  {{ tiempos_pedido_finalizado.total_horas || '' }} horas
                </p>
                <p
                  class="pill-categoria2"
                  *ngIf="
                    distribuidor.tiempo_entrega &&
                    tiempos_pedido_finalizado.total_horas >= distribuidor.tiempo_entrega.split(' ')[0] &&
                    tiempos_pedido_finalizado.total_horas <= distribuidor.tiempo_entrega.split(' ')[2]
                  ">
                  Tiempo cumplido
                </p>
              </div>
            </div>
          </ng-template>
        </div>
        <!--Productos-->
        <div class="contenedor-productos">
          <div class="card pb-4">
            <div class="card-body p-4 mb-4 overflow-hidden">
              <!--Productos - Unidades-->
              <div class="row">
                <div class="col-12 titulo-scroll mb-3 d-flex flex-row justify-content-between">
                  <p class="m-0">
                    {{ cantProductos }} producto{{ cantProductos == 1 ? '' : 's' }} - {{ cantUnidades }} unidad{{
                      cantUnidades == 1 ? '' : 'es'
                    }}
                    en tu pedido
                  </p>
                  <!--<button (click)="editar()" class="btn-editar text-center"
                  *ngIf="estadosEditar.includes(pedido.estado)"
                  >
                    Editar
                  </button>-->
                </div>
              </div>
              <hr />
              <!--Scroll de productos-->
              <div class="row">
                <div class="col-12">
                  <div id="scroll-productos">
                    <!--Producto-->
                    <!--<div class="producto" *ngFor="let item of completedProduct; let i = index">
                      <div class="row mb-4">
                        <div
                          class="col-12 px-5 pt-1 px-sm-3 col-xs-3 col-sm-2 d-flex flex-column justify-content-start align-items-center">
                          <div class="row carouselCont" *ngIf="item.promocion === true">
                            <ngb-carousel class="w-100">
                              <ng-template ngbSlide *ngFor="let img_src of item.fotos" class="w-100">
                                <div class="w-100 col-12 d-flex justify-content-center">
                                  <img [src]="img_src" class="img-prod rounded" [alt]="'Foto de ' + item.nombre" />
                                </div>
                              </ng-template>
                            </ngb-carousel>
                          </div>
                          <img
                            *ngIf="item.promocion === false"
                            src="{{ item.fotos[0] || product_placeholder }}"
                            class="icon-producto"
                            alt="'Foto de ' + {{ item?.nombre }}" />
                        </div>
                        <div *ngIf="item.precioEspecial.length > 0">


 <div class="col-12 col-xs-4 col-sm-7 d-flex flex-column justify-content-between">
  <p class="desc-prod text-right text-sm-left">
    {{ item?.nombre }}
  </p>

  <div class="d-flex flex-column justify-content-between">
    
    <p class="m-0">
      {{ item.precioEspecial[0].precioActual | currency : '$ ' : 'symbol' : '1.0-0' }}und.
    </p>
    <p class="m-0">
      {{ item.precioEspecial[0].precioActual * item.precios[0].und_x_caja | currency : '$ ' : 'symbol' : '1.0-0' }}caja.
    </p>
    <p class="m-0">
      {{
        item.precioEspecial[0].precioActual / item.precios[0].cantidad_medida
          | currency : '$ ' : 'symbol' : '1.0-0'
      }}/{{ item.precios[0].unidad_medida }}
    </p>
    <p class="desc-prod m-0" *ngIf="item.promocion === false">
      Código distribuidor: {{ item?.codigo_distribuidor_producto }}
    </p>
    <p class="desc-prod m-0" *ngIf="item.promocion === true">
      Código promoción: {{ item?.codigo_promo }}
    </p>
    <p class="desc-prod m-0" *ngIf="item.saldos === true">
      Código saldo: {{ item?.codigo_promo }}
    </p>
    <p class="mt-2 desc-prod pts-feat">{{ item?.precios[0].puntos_ft_unidad || 0 }} Pts FT</p>
  </div>
</div>
<div class="col-12 col-sm-3 text-right d-flex flex-column justify-content-between pl-0">
  <p class="desc-prod d-flex flex-column">
    <strong class="m-0">{{ item?.unidad }} und{{ item?.unidad !== 1 ? 's' : '' }}.</strong>
    <span class="m-0 mt-2" *ngIf="item.promocion === false">
      Total en cajas: {{ totalCajas(item.precios[0].und_x_caja, item.unidad) || 0 }}
    </span>
  </p>
  <p class="desc-prod">
    <strong>
      Total:
      {{ item.precioEspecial[0].precioActual * item?.unidad | currency : '$ ' : 'symbol' : '1.0-0' }}
    </strong>
  </p>
</div>




                        </div>
                        <div *ngIf="item.precioEspecial.length === 0">
 <div class="col-12 col-xs-4 col-sm-7 d-flex flex-column justify-content-between">
  <p class="desc-prod text-right text-sm-left">
    {{ item?.nombre }}
  </p>

  <div class="d-flex flex-column justify-content-between">
    <p class="m-0" *ngIf="item.saldos === false && !item.porcentaje_descuento">
      {{ item.precios[0].precio_unidad | currency : '$ ' : 'symbol' : '1.0-0' }}und.
    </p>
    <p class="m-0" *ngIf="item.saldos === false && item.porcentaje_descuento">
      {{ item.precio_original | currency : '$ ' : 'symbol' : '1.0-0' }}und.
    </p>
    <p class="m-0" *ngIf="item.saldos === true">
      {{ item.precios[0].precio_descuento | currency : '$ ' : 'symbol' : '1.0-0' }}und.
    </p>
    <p class="m-0" *ngIf="item.promocion === false && item.saldos === false && !item.prodDescuento">
      {{ item.precios[0].precio_caja | currency : '$ ' : 'symbol' : '1.0-0' }}caja.
    </p>
    <p class="m-0" *ngIf="item.promocion === false && item.saldos === false && item.porcentaje_descuento">
      {{ item.precio_caja | currency : '$ ' : 'symbol' : '1.0-0' }}caja.
    </p>
    <p class="m-0" *ngIf="item.promocion === false && item.saldos === false && !item.prodDescuento">
      {{
        item.precios[0].precio_unidad / item.precios[0].cantidad_medida
          | currency : '$ ' : 'symbol' : '1.0-0'
      }}/{{ item.precios[0].unidad_medida }}
    </p>
    <p class="m-0" *ngIf="item.promocion === false && item.saldos === false && item.porcentaje_descuento">
      {{
        item.precio_original / item.precios[0].cantidad_medida
          | currency : '$ ' : 'symbol' : '1.0-0'
      }}/{{ item.precios[0].unidad_medida }}
    </p>
    <p class="m-0" *ngIf="item.saldos === true">
      {{
        item.precios[0].precio_descuento / item.precios[0].cantidad_medida
          | currency : '$ ' : 'symbol' : '1.0-0'
      }}/{{ item.precios[0].unidad_medida }}
    </p>
    <p class="desc-prod m-0" *ngIf="item.promocion === false">
      Código distribuidor: {{ item?.codigo_distribuidor_producto }}
    </p>
    <p class="desc-prod m-0" *ngIf="item.promocion === true">
      Código promoción: {{ item?.codigo_promo }}
    </p>
    <p class="desc-prod m-0" *ngIf="item.saldos === true">
      Código saldo: {{ item?.codigo_promo }}
    </p>
    <p class="mt-2 desc-prod pts-feat">{{ item?.precios[0].puntos_ft_unidad || 0 }} Pts FT</p>
  </div>
</div>
<div class="col-12 col-sm-3 text-right d-flex flex-column justify-content-between pl-0">
  <p class="desc-prod d-flex flex-column">
    <strong class="m-0">{{ item?.unidad }} und{{ item?.unidad !== 1 ? 's' : '' }}.</strong>
    <span class="m-0 mt-2" *ngIf="item.promocion === false">
      Total en cajas: {{ totalCajas(item.precios[0].und_x_caja, item.unidad) || 0 }}
    </span>
  </p>
  <p class="desc-prod">
    <strong *ngIf="item.saldos === false && !item.prodDescuento">
      Total:
      {{ item.precios[0].precio_unidad * item?.unidad | currency : '$ ' : 'symbol' : '1.0-0' }}
    </strong>
    <strong *ngIf="item.saldos === false && item.prodDescuento">
      Total:
      {{ item.precio_original * item?.unidad | currency : '$ ' : 'symbol' : '1.0-0' }}
    </strong>
    <strong *ngIf="item.saldos === true">
      Total:
      {{
        item.precios[0].precio_descuento * item?.unidad | currency : '$ ' : 'symbol' : '1.0-0'
      }}
    </strong>
  </p>
</div>
                        </div>
                      </div>
                      <hr />
                    </div>-->

                    <div class="prod" *ngFor="let prod of completedProduct">
                      <div class="row m-0">
                        <!--Imagen-->
                        <div class="d-flex justify-content-center col-3 pl-0">
                          <img *ngIf="prod.promocion === false" class="img-producto" [src]="prod.fotos[0]"
                            alt="Producto" />
                          <div class="row carouselCont" *ngIf="prod.promocion === true">
                            <ngb-carousel class="w-100">
                              <ng-template ngbSlide *ngFor="let img_src of prod.fotos" class="w-100">
                                <div class="w-100 col-12 d-flex justify-content-center">
                                  <img [src]="img_src" class="img-prod rounded" [alt]="'Foto de ' + prod.nombre" />
                                </div>
                              </ng-template>
                            </ngb-carousel>
                          </div>
                        </div>
                        <!--Informacion del producto-->
                        <div class="col-6 d-flex flex-column justify-content-between">
                          <strong class="m-0">{{ prod.nombre }}</strong>
                          <div *ngIf="prod.precioEspecial.length > 0">

                             <!-- Precio por unidad -->
                          <p class="m-0">
                            {{ prod.precioEspecial[0].precioActual  | currency: '$ ':'symbol':'1.0-0' }}und.
                          </p>
                          <!-- Precio Caja -->
                          <p class="m-0">
                            {{ prod.precioEspecial[0].precioActual * prod.precios[0].und_x_caja | currency : '$ ' : 'symbol' : '1.0-0' }}caja.
                          </p>
                          <!-- unidad de medida y cantidad medida -->
                          <p class="m-0" *ngIf="prod.promocion === false && prod.saldos === false && !prod.porcentaje_descuento">
                            {{
                              prod.precioEspecial[0].precioActual / prod.precios[0].cantidad_medida
                                | currency : '$ ' : 'symbol' : '1.0-0'
                            }}/{{ prod.precios[0].unidad_medida }}
                          </p>



                          </div>
                          <div *ngIf="prod.precioEspecial.length === 0">
                             <!-- Precio por unidad -->
                          <p class="m-0" *ngIf="prod.saldos === false && !prod.porcentaje_descuento">
                            {{ prod.precios[0].precio_unidad | currency: '$ ':'symbol':'1.0-0' }}und.
                          </p>
                          <p class="m-0" *ngIf="prod.saldos === false && prod.porcentaje_descuento">
                            {{ prod.precio_original | currency : '$ ' : 'symbol' : '1.0-0' }}und.
                          </p>
                          <p class="m-0" *ngIf="prod.saldos === true">
                            {{ prod.precios[0].precio_descuento | currency: '$ ':'symbol':'1.0-0' }}und.
                          </p>
                          <!-- Precio Caja -->
                          <p class="m-0" *ngIf="prod.promocion === false && prod.saldos === false && !prod.porcentaje_descuento">
                            {{ prod.precios[0].precio_caja | currency: '$ ':'symbol':'1.0-0' }}caja.
                          </p>
                          <p class="m-0" *ngIf="prod.promocion === false && prod.saldos === false && prod.porcentaje_descuento">
                            {{ prod.precio_caja | currency : '$ ' : 'symbol' : '1.0-0' }}caja.
                          </p>
                          <!-- unidad de medida y cantidad medida -->
                          <p class="m-0" *ngIf="prod.promocion === false && prod.saldos === false && !prod.porcentaje_descuento">
                            {{
                            prod.precios[0].precio_unidad / prod.precios[0].cantidad_medida
                            | currency: '$ ':'symbol':'1.0-0'
                            }}/{{ prod.precios[0].unidad_medida }}
                          </p>
                          <p class="m-0" *ngIf="prod.promocion === false && prod.saldos === false && prod.porcentaje_descuento">
                            {{
                              prod.precio_original / prod.precios[0].cantidad_medida
                                | currency : '$ ' : 'symbol' : '1.0-0'
                            }}/{{ prod.precios[0].unidad_medida }}
                          </p>
                          <p class="m-0" *ngIf="prod.saldos === true">
                            {{
                            prod.precios[0].precio_descuento / prod.precios[0].cantidad_medida
                            | currency: '$ ':'symbol':'1.0-0'
                            }}/{{ prod.precios[0].unidad_medida }}
                          </p>

                          </div>
                         


                          <!-- Codigos producto -->
                          <p class="m-0" *ngIf="prod.promocion === false">
                            Código distribuidor: {{ prod.codigo_distribuidor_producto }}
                          </p>
                          <p class="m-0" *ngIf="prod.promocion === false && prod.saldos === true">
                            Código saldos: {{ prod.codigo_promo }}
                          </p>
                          <p class="m-0" *ngIf="prod.promocion === true">
                            Código promoción: {{ prod.codigo_promo }}
                          </p>
                          <!-- Puntos ft por producto -->
                          <p style="font-size: 0.9rem" class="m-0"
                            *ngIf="prod.promocion === false && prod.saldos === false">{{
                            prod.precios[0].puntos_ft_unidad }} pts FT</p>
                        </div>
                        <!--Totales-->
                        <div class="col-3 text-right d-flex flex-column justify-content-between">
                          <!--Unidades-->
                          <h6>
                            <strong class="m-0">
                              {{ prod.unidadesCompradas }} und{{ prod.unidadesCompradas !== 1 ? 's' : '' }}.
                            </strong>
                            <p class="m-0 mt-2" *ngIf="prod.promocion === false">
                                    Total en cajas: {{ totalCajas(prod.precios[0].und_x_caja, prod.unidad) || 0 }}

                            </p>
                          </h6>
                          <!--Precio total-->

                          <div *ngIf="prod.precioEspecial.length > 0">

                            <h6 class="precio-prod">
                              <strong>
                                Total:
                                <span >
                                  {{ prod.precioEspecial[0].precioActual* prod.unidad | currency: '$' : 'symbol' :
                                  '1.0-0' }}
                                </span>
                              </strong>
                            </h6>


                          </div>
                          <div *ngIf="prod.precioEspecial.length === 0">
                            <h6 class="precio-prod">
                              <strong>
                                Total:
                                <span *ngIf="prod.saldos === false && !prod.prodDescuento">
                                  {{prod.precios[0].precio_unidad * prod.unidad | currency: '$' : 'symbol' :
                                  '1.0-0' }}
                                </span>
                                <span *ngIf="prod.saldos === false && prod.prodDescuento">
                                  Total:
                                  {{ prod.precio_original * prod.unidad | currency : '$ ' : 'symbol': '1.0-0' }}
                                </span>
                                <span *ngIf="prod.saldos === true">
                                  {{prod.precios[0].precio_descuento * prod.unidad | currency: '$' :
                                  'symbol' : '1.0-0' }}
                                </span>
                              </strong>
                            </h6>
                          </div>
                      


                        </div>
                      </div>
                      <hr />
                    </div>





                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
